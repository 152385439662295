<script>
  // console.clear();
  import {
    http
  } from "@/helpers/easyindustriaapi/config";
  import {
    notificationMethods
  } from "@/state/helpers";
  //import { listEstados } from "@/components/defaults/estados";
  import {
    httpCEP
  } from "@/helpers/easyindustriaapi/configViaCEP";
  // import Layout from "@/views/layouts/main";
  // import PageHeader from "@/components/page-header";

  export default {
    props: {
      currentEmpresa: {
        type: Object,
        required: true
      },
      oldLResponsible:{type: Object}
      //currentResponsible: { type: Object, required: true },
    },
    components: {},
    data() {
      return {
        loader: {
          get: false,
        },
        items: [{
            text: "Dashboard",
            href: "/",
          },
          {
            text: "Parâmetros",
            href: "/",
            active: true,
          },
          {
            text: "Plano de Contas",
            href: "/parametros/plano",
            active: true,
          }
        ],
        logoUrl: "@/assets/crescer.png",
        titleBody: "Inserindo",
        responsible: {
          id: null,
          name: null,
          cpf: "",
          rg: "",
          email: "",
          celular: "",
          cep: "",
          rua: "",
          numero: "",
          complemento: "",
          bairro: "",
          cidade: null,
          cidade_nome: "",
          uf: null,
          uf_nome: "",
          empresa_id: null,
          status: true,
        },
        listCidades: [],
        listUFs: [],
        listPaises: [],
        paisDefault: {
          id: 1058,
          nome: "Brasil",
          created_at: null,
          updated_at: null,
        },
        inserindo: true,
        submitted: false,
        responsavel:{
          name: null,
          cpf: null,
          nascimento: null,
          email: null,
          telefone: null,
          rua: null,
          bairro: null,
          num_casa: null,
        }
      };
    },
    validations: {
      // responsible: {
      //   cpf: { required },
      //   pes_apelido: { required },
      //   pes_razao: { required },
      //   rua: { required },
      //   numero: { required },
      //   bairro: { required },
      //   cidade: { required },
      //   uf: { required },
      //   pais_id: { required },
      //   cep: { required },
      //   email: { required, email },
      //   empresa_id: { required },
      //   pes_indfinal: { required },
      //   pes_indiedest: { required },
      // },
    },
    created(){
     this.responsible = this.oldLResponsible
     this.responsavel = this.oldLResponsible
    },
    mounted() {
      this.$emit("newTitle", "Incluir Responsável");
      // this.listPaises = [this.paisDefault];
      //this.responsible.pais_id = 1;
      //this.responsible.empresa_id = this.currentEmpresa.id;
      // this.listUFs = listEstados;
      //this.getCidadesStart(29);
      console.log("Monstrando Obejeto resposaveis")
      console.log(this.responsible)
    },
    computed: {
      notification() {
        return this.$store ? this.$store.state.notification : null;
      },
      legendCPFCNPJ() {
        if (this.responsible && this.responsible.cpf) {
          return this.responsible.cpf.length > 11 ? "CNPJ" : "CPF";
        }
        return "CPF/CNPJ";
      },
      getCidadeId(objects, key) {
        return objects.map(function(object) {
          return object[key];
        });
      },
      isCNPJ() {
        return this.legendCPFCNPJ === "CNPJ";
      },
    },
    methods: {
      ...notificationMethods,
      
      
      abr_multiselect(valor) {
        console.log("entrar");
        if (valor == 1) {
          console.log(valor)
          this.show_multiselect = true
        } else {
          console.log("esntrou no elsse" + valor)
          this.show_multiselect = false
        }
      },
      setPut() {
       // this.loader.get = true;
        // setTimeout(function() {
        //   this.loader.get = false;
        // }, 2000);
      console.log("estou no doPost")
      // this.responsavel.rg = 1;
      // this.responsavel.celular = 1;
      // this.responsavel.cep = 1;
      // this.responsavel.numero = 1;
      // this.responsavel.complemento = 1;
      // this.responsavel.cidade = 1;
      // this.responsavel.uf = 1;
      console.log(this.responsavel)
      
       this.$emit("doPut", this.responsavel);
      },
      selecionar_plano(event) {
        this.plano.woner_id = event.id
      },
      selecionar_tipos(event) {
        this.plano.classificacao = event.id
      },
      seleciona_orientacoes(event) {
        this.plano.natureza = event.id
      }
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.responsible.$error) {
        this.makeToast("danger", "Erro de validação!");
      } else {
        this.setPost();
      }
    },
    setPost() {
      this.$emit("gravar", this.responsible);
    },
    back() {
      this.$emit("back");
    },
    changeLoader(value) {
      this.loader.get = value;
    },
    clearEndereco() {
      this.responsible.rua = "";
      this.responsible.bairro = "";
      this.responsible.complemento = "";
      this.responsible.cidade_nome = "";
      this.responsible.uf_nome = "";
    },
    async getCEP(value) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      var cep = value.replace(/\D/g, "");
      this.loader.get = true;
      await httpCEP
        .get(cep + "/json")
        .then((response) => {
          this.meuCallbackCEP(response.data);
        })
        .finally(() => {
          this.loader.get = false;
        })
        .catch((err) => {
          console.log(err);
          this.loader.get = false;
        });
    },
    async meuCallbackCEP(conteudo) {
      if (!("erro" in conteudo)) {
        this.clearEndereco();
        this.responsible.rua = conteudo.logradouro;
        this.responsible.bairro = conteudo.bairro;
        this.responsible.complemento = conteudo.complemento;
        const selectedState = this.listUFs.filter(
          (obj) => obj.uf.toLowerCase() === conteudo.uf.toLowerCase()
        )[0];
        this.responsible.uf_nome = selectedState.nome;
        this.responsible.uf = selectedState.id;
        await this.getCidadesStart(this.responsible.uf);
        const selectedCity = this.listCidades.filter(
          (cidade) => cidade.nome === conteudo.localidade
        )[0];
        this.responsible.cidade_nome = conteudo.localidade;
        this.responsible.cidade = selectedCity.id;
      } else {
        //CEP não Encontrado.
        this.clearEndereco();
        alert("CEP não encontrado.");
      }
    },
    async getUFs(event) {
      if (this.responsible.pais_id != 0) {
        this.loader.get = true;
        await http
          .get("pais/" + event.target.value + "/estados")
          .then((response) => {
            this.listUFs = response.data;
          })
          .finally(() => {
            this.loader.get = false;
          })
          .catch((err) => {
            console.log(err);
            this.loader.get = false;
          });
      }
      this.responsible.pais_id = event.target.value;
      this.loader.get = false;
    },
    async getCidadesStart(estadoId) {
      let id = estadoId && estadoId.target ? estadoId.target.value : estadoId;
      this.loader.get = true;
      await http
        .get("pais/" + this.responsible.pais_id + "/estado/" + id + "/cidades")
        .then((response) => {
          this.listCidades = response.data;
        })
        .finally(() => {
          this.loader.get = false;
        })
        .catch((err) => {
          console.log(err);
          this.loader.get = false;
        });
      this.responsible.uf = id;
    },
  };
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <form>
        <!-- comentrio -->
        <!-- subino coigo certo -->
        <!-- subdinddo codigo com pulll origin -->
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-4">
            <label for="nome_profissional">Nome do responsável:</label>
            <input  type="text" class="form-control" maxlength="100" placeholder="nome do profissional" v-model="responsavel.name">
          </div>

          <div class="form-group col-md-4">
            <label for="nome_profissional">CPF do responsável:</label>
            <input type="text" class="form-control" v-mask="'###.###.###-##'" placeholder="12345678901" v-model="responsavel.cpf">
          </div>

          <div class="form-group col-md-4">
            <label for="nome_profissional">Data de nascimento:</label>
            <input type="date" class="form-control" placeholder="" v-model="responsavel.nascimento">
          </div>

          <div class="form-group col-md-4">
            <label for="contato_email">E-mail:</label>
            <input type="email" class="form-control" placeholder="Ex: profisional@email.com" v-model="responsavel.email">
          </div>

          <div class="form-group col-md-4">
            <label for="contato_telefonico">Contato telefônico:</label>
            <input type="text" class="form-control" v-mask="'## #####-####'" placeholder="Ex: 12934567889" v-model="responsavel.celular">
          </div>
        </div>

        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-4">
            <label for="nome_profissional">Rua:</label>
            <input type="text" class="form-control" maxlength="40" placeholder="Travessa do caiano" v-model="responsavel.rua">
          </div>

          <div class="form-group col-md-4">
            <label for="contato_email">Bairro:</label>
            <input type="text" class="form-control" maxlength="40" placeholder="Ex: Centro" v-model="responsavel.bairro">
          </div>

          <div class="form-group col-md-4">
            <label for="contato_telefonico">Número da casa:</label>
            <input type="text" class="form-control" maxlength="5" placeholder="Ex: 65B" v-model="responsavel.numero">
          </div>
        </div>

          

        <div class="col-md-12 mt-4">
          <button type="submit" class="btn btn-primary" style="margin-right: 20px;" @click.prevent="setPut()">Atualizar</button>
        </div>
      </form>
    </div>
  </div>


  <!-- <div class="card-body" v-if="!isHide">
        <Insert
         v-if="inserindo"
         :currentEmpresa="currentEmpresa"
         @newTitle="changeHomeTitle"
         @doPost="doPost "
        />
        <Edit
         v-if="editando"
         :currentEmpresa="currentEmpresa"
         :oldLocalEstoque="currentLocalEstoque"
         @newTitle="changeHomeTitle"
         @doPut="doPut"
        />
      </div>
      <List v-if="isHide"
       :currentEmpresa="currentEmpresa"
       :listLocaisEstoque="planoConta"
       :hide="isHide"
       @newTitle="changeHomeTitle"
       @edit="edit"
       @doDelete="doDelete"
      />
     -->
  <!-- </layout> -->
</template>