<script>
  import appConfig from "@/app.config";
  import {
    http
  } from '@/helpers/easyindustriaapi/config';
    import Layout from "@/views/layouts/main"
  import PageHeader from "@/components/page-header";
   import List from "./list";
   import Insert from "./insert";
   import Edit from "./edit";
  export default {
    page: {
      title: 'Lista de clientes',
      meta: [{
        name: 'description',
        content: appConfig.description
      }],
    },
        components: {List,Insert,Edit,PageHeader,Layout},
    data() {
      return {
        currentLocalEstoque: {},
        planoConta: [],
        inserindo: false,
        editando: false,
        titleBody: 'Lista de Responsáveis',
        items: [{
            text: "Dashboard",
            href: "/",
          },
          {
            text: "Responsáveis",
            href: "/",
            active: true,
          },
          {
            text: "Incluir Responsáveis",
            href: "/cadastros/profissionais",
            active: true,
          }
        ],
      }
    },
    computed: {
      isHide() {
        return (!this.inserindo && !this.editando);
      },
    },
    created() {
      this.currentUser = JSON.parse(localStorage.getItem('user'));
      this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
    },
    mounted() {
      this.getData();
    },
    methods: {
      makeToast(variant = null, message = 'Error generic') {
        this.counter++;
        this.$bvToast.toast(message, {
          title: `Notificação`,
          toaster: 'b-toaster-bottom-right',
          variant: variant,
          solid: true,
          appendToast: true
        });
      },
      changeHomeTitle(newTitle) {
        this.titleBody = newTitle;
      },
      back() {
        this.inserindo = false;
        this.editando = false;
      },
      onLoader() {
        if (document.getElementById("preloader").style.display === "none") {
          document.getElementById("preloader").style.display = "block";
          document.getElementById("status").style.display = "block";
        }
      },
      offLoader() {
        if (document.getElementById("preloader").style.display === "block") {
          setTimeout(function() {
            document.getElementById("preloader").style.display = "none";
            document.getElementById("status").style.display = "none";
          }, 2500);
        }
      },
      async getData() {
        this.onLoader();
        try {
          let response = await http.get('/responsible?empresa_id=' + this.currentEmpresa.id);
          if (response.status === 200) {
            this.planoConta = response.data;
            console.log(this.planoConta)
            this.offLoader();
          }
        } catch (error) {
          let messageErro = error.response.data;
          switch (error.response.status) {
            case 406:
              this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
              this.offLoader();
              break;
            case 404:
              this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
              this.offLoader();
              break;
            default:
              this.makeToast('danger', error.message);
              this.offLoader();
              break;
          }
        }
      },
      insert() {
        this.inserindo = !this.inserindo;
      },
      edit(local) {
        console.log(local);
        this.currentLocalEstoque = local;
        this.editando = !this.editando;
      },
      async doPost(plano) {
        plano.empresa_id = this.currentEmpresa.id;
        let response = await http.post('/responsible?empresa_id=' + this.currentEmpresa.id, plano)
          .catch((error) => {
            this.makeToast('danger', error.response);
            this.offLoader();
          });
        if (response.status === 200) {
          this.getData();
          this.back();
          this.makeToast('success', 'Registro incluído');
        }
      },
      async doPut(plano) {
        this.onLoader();
        plano.user_id = this.currentUser.id;
        let response = await http.put('/responsible/' + plano.id + '?empresa_id=' + this.currentEmpresa.id, plano)
          .catch((error) => {
            this.offLoader();
            this.makeToast('danger', error.response);
          });
        if (response.status === 200) {
          this.getData();
          this.back();
          this.makeToast('success', 'Registro alterado');
        }
      },
      async doDelete(plano) {
        this.onLoader();
        plano.empresa_id = this.currentEmpresa.id;
        let response = await http.delete('/responsible/' + plano.id + '?empresa_id=' + this.currentEmpresa.id, plano)
          .catch((error) => {
            this.offLoader();
            this.makeToast('danger', error.response);
          });
        if (response.status === 200) {
          this.getData();
          this.back();
          this.makeToast('warning', 'Registro excluído');
        }
      },
    },
  }
</script>

<template>
  <!-- <div class="row">
    <div class="col-md-12">
      <div class="card">
      
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6"></div>
            <div class="col-sm-12 col-md-6 text-md-right">
              <button @click="insert()" v-if="isHide" type="button" class="btn btn-success">+ Incluir Plano de Contas</button>
              <button @click="back()" v-if="!isHide" class="btn btn-secondary">Voltar</button>
            </div>
          </div>
        </div>
        
        <div class="card-body" v-if="!isHide">
          <Insert
           v-if="inserindo"
           :currentEmpresa="currentEmpresa"
           @newTitle="changeHomeTitle"
           @doPost="doPost "
          />
          <Edit
           v-if="editando"
           :currentEmpresa="currentEmpresa"
           :oldLocalEstoque="currentLocalEstoque"
           @newTitle="changeHomeTitle"
           @doPut="doPut"
          />
        </div>
        <List v-if="isHide"
         :currentEmpresa="currentEmpresa"
         :listResponsibles="planoConta"
         :hide="isHide"
         @newTitle="changeHomeTitle"
         @edit="edit"
         @doDelete="doDelete"
        />
      </div>
    </div>
  </div> -->
  <Layout> 
  <PageHeader :title="titleBody" :items="items" />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
      
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6"></div>
            <div class="col-sm-12 col-md-6 text-md-right">
              <button @click="insert()" v-if="isHide" type="button" class="btn btn-success">+ Incluir Responsável</button>
              <button @click="back()" v-if="!isHide" class="btn btn-secondary">Voltar</button>
            </div>
          </div>
        </div>
        
        <div class="card-body" v-if="!isHide">
          <Insert
           v-if="inserindo"
           :currentEmpresa="currentEmpresa"
           @newTitle="changeHomeTitle"
           @doPost="doPost "
          />
          <Edit
           v-if="editando"
           :currentEmpresa="currentEmpresa"
           :oldLResponsible="currentLocalEstoque"
           @newTitle="changeHomeTitle"
           @doPut="doPut"
          />
        </div>
        <List v-if="isHide"
         :currentEmpresa="currentEmpresa"
         :listResponsibles="planoConta"
         :hide="isHide"
         @newTitle="changeHomeTitle"
         @edit="edit"
         @doDelete="doDelete"
        />
      </div>
    </div>
  </div>
  </Layout> 
</template>